@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    
    .h-278 {
      min-height: 725px;
      max-height: 725px;
    }

    .h-250 {
      height: 400px;
    }
    .w-400{
      max-width:400px;
      min-width: 400px;
    }
    .w-200{
      max-width:350px;
      min-width: 350px;
    }
    .w-220{
      width:200px;
    }
    
    .h-22{
      max-height: 500px;
    }

    .h-24 {

    }

  
  }